/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import MediaPlayer from "layouts/pages/widgets/components/MediaPlayer";
import Card from "@mui/material/Card";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import Paper from '@mui/material/Paper';
import MDBadge from "components/MDBadge";

// Material Dashboard 2 PRO React examples
import DefaultItem from "examples/Items/DefaultItem";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "examples/Cards/BookingCard";
import { useLocation, NavLink, useNavigate } from "react-router-dom";

// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";
import DataTable from "examples/Tables/DataTable";
import DefaultCell from "./DefaultCell";
import officeDark from "assets/images/office-dark.jpg";
import { useState, useEffect } from "react";

import Firebase from "firebase";



import MDInput from "components/MDInput";
function Chefnotes() {
  const { sales, tasks } = reportsLineChartData;
  const location = useLocation()
  const { storeUiniqId } = location.state
  const [newCardForm, setNewCardForm] = useState(false);
  const [formValue, setFormValue] = useState("");
  const [chefNotesData, setChefNotesData] = useState();

  const openNewCardForm = (event, id) => setNewCardForm(id);
  const closeNewCardForm = () => setNewCardForm(false);
  const handeSetFormValue = ({ currentTarget }) => setFormValue(currentTarget.value);
  const navigate = useNavigate();

  useEffect(() => {
    if(!storeUiniqId){
      return
    }
    const onChildAdd = Firebase.database()
      .ref(`/discussions/${storeUiniqId}/`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
         setChefNotesData(Object.values(data))
        }
       
      });
    return () => Firebase.database().ref(`/discussions/${storeUiniqId}/`).off('value', onChildAdd);
  }, [storeUiniqId])

  // Action buttons for the BookingCard
  const actionButtons = (
    <>
      <Tooltip title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Edit" placement="bottom">
        <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );
 
  return (
    <DashboardLayout>
      {chefNotesData?.map(item => {
        return (
          <>
           {item.image ?
              (<>
                 <Card>
                  <MDBox p={2}>
                      <MDBox display="flex" justifyContent="space-between" alignItems="center">
                        {item.name === 'cheffnote' ? (
                        <MDTypography variant="h6" fontWeight="medium">
                          {item.name}
                        </MDTypography>) : (
                          <MDTypography>
                          {item.name}
                        </MDTypography>
                        )}
                      </MDBox>
                    <MDBox component="img" src={item.image} width="100%" borderRadius="lg" mb={1} />
                    {item.badges && item.badges.split(",").map(b => {
                        return (
                          <MDBadge size="xs" color={"success"} badgeContent={b} container style={{padding: "5px"}}/>
                        )
                      })}                    <MDBox mt={1} mb={2}>
                      <MDTypography variant="body2" color="text">
                        {item.message}
                      </MDTypography>
                    </MDBox>
                    </MDBox>
                  </Card>
              </>
              ) :
              (<>
                <Card>   
                  <MDBox p={2}>
                      <MDBox display="flex" justifyContent="space-between" alignItems="center">
                        {item.name === 'cheffnote' ? (
                        <MDTypography variant="h6" fontWeight="medium">
                          {item.name}
                        </MDTypography>) : (
                          <MDTypography>
                          {item.name}
                        </MDTypography>
                        )}
                      </MDBox>
                      
                      <MDBox mt={1} mb={2}>
                        <MDTypography variant="body2" color="text">
                          {item.message}
                        </MDTypography>
                      </MDBox>
                      {item.badges && item.badges.split(",").map(b => {
                        return (
                          <MDBadge size="xs" color={"success"} badgeContent={b} container style={{padding: "5px"}}/>
                        )
                      })}
                  </MDBox>
                </Card>
              </>
              )
            }
            <br />
          </>
        )
      })}
    
   
    <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
      <MDTypography variant="h6">Add A Comment</MDTypography>
      <MDButton size="small" iconOnly onClick={(event) => openNewCardForm(event, "id")}>
        <Icon
          sx={{
            fontWeight: "bold"
          }}
        >
          add
        </Icon>
      </MDButton>
      
      
    </MDBox>
    
    {newCardForm === "id" ? null : (<><br /><br /></>)}
        
    {newCardForm === "id" ? (
      <MDBox my={2.5}>
        <MDInput
          value={formValue}
          rows="4"
          onChange={handeSetFormValue}
          multiline
          fullWidth
        />
        <MDBox display="flex" mt={2}>
          <MDButton
            variant="gradient"
            color="success"
            size="small"
            onClick={() => {
              Firebase.database().ref(`/discussions/${storeUiniqId}/`).push({name: "user", message: formValue, badges: "review"})
              setFormValue("");
              closeNewCardForm();
            }}
          >
            add
          </MDButton>
          <MDBox ml={1}>
            <MDButton
              variant="gradient"
              color="light"
              size="small"
              onClick={closeNewCardForm}
            >
              cancel
            </MDButton>
          </MDBox>
        </MDBox>
        <br />
        <br />
      </MDBox>
    ) : null}
             
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={() => {
        navigate(-1);
       }}
    >
      <Icon fontSize="large" color="inherit">
        home
      </Icon>
    </MDBox>
    </DashboardLayout>
  );
}

export default Chefnotes;
