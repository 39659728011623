/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import MediaPlayer from "layouts/pages/widgets/components/MediaPlayer";
import Card from "@mui/material/Card";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import Paper from '@mui/material/Paper';

// Material Dashboard 2 PRO React examples
import DefaultItem from "examples/Items/DefaultItem";
import { useLocation, Link, useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "examples/Cards/BookingCard";

// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";
import DataTable from "examples/Tables/DataTable";
import DefaultCell from "./DefaultCell";
import TimelineList from "examples/Timeline/TimelineList";
import TimelineItem from "examples/Timeline/TimelineItem";
import timelineData from "layouts/pages/projects/timeline/data/timelineData";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import Modal from '@mui/material/Modal';
import * as React from 'react';
import image5 from "assets/images/products/product-details-5.jpg";
import MDBadgeDot from "components/MDBadgeDot";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

function Myorders() {
  const location = useLocation()
  const { previousOrders } = location.state
  const { sales, tasks } = reportsLineChartData;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const colors = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ];
  const icons = [
    "notifications",
    "inventory_2",
    "shopping_cart",
    "payment",
    "vpn_key",
    "inbox",
    "campaign",
    "archive",
    "sports_esports",
  ]
  let timelineData = []
  for (let index = 0; index < previousOrders.length; index++) {
    const element = previousOrders[index];
    let desc = "";
    let titleCost = 0;
    const a = Object.values(Object.values(element)[0])
    for (let index = 0; index < a.length; index++) {
      const element = a[index];
      const t = Object.values(element)[0]
      titleCost = titleCost + Number(t.split("totalCost:")[1].split("||productimage")[0])
      desc = desc.concat(` ${t.split("name:")[1].split("||id:")[0]} price ${t.split("||price:")[1].split("||quantity:")[0]} quantity ${t.split("||quantity:")[1].split("||totalCost:")[0]}`)
    }
    

    timelineData.push({
      color: colors[Math.floor(Math.random() * 6)],
      icon: icons[Math.floor(Math.random() * 8)],
      title: `${titleCost}`,
      dateTime: Object.keys(element)[0],
      description:desc
    })
  }


  const renderTimelineItems = timelineData.map(
    ({ color, icon, title, dateTime, description, badges, lastItem }) => (
      <TimelineItem
        key={title + color}
        color={color}
        icon={icon}
        title={title}
        dateTime={dateTime}
        description={description}
        badges={badges}
        lastItem={lastItem}
        
      />
    )
  );
 
  return (
    <DashboardLayout>
    <Modal
        open={open}
        onClose={handleClose}
        style={{
          minHeight: '100vh',
          maxHeight: '100vh',
          // position:'absolute',
          // top:'10%',
          // left:'10%',
          overflow:'scroll',
          // height:'100%',
          // display:'block'
        }}
      >
        {/* <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <MDButton variant="text" color="error" size="small">
            <Icon>close</Icon>
          </MDButton>
        </MDBox> */}
        <OrderDetails />
        {/* <Card  style={{
         
          top: '20%',
          padding: "15px"
        }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6">Channels asdf asd fasdf </MDTypography>
        <MDButton variant="text" color="error" size="small" onClick={handleClose}>
          <Icon>close</Icon>
        </MDButton>
      </MDBox>
      <MDBox mt={3}>
        <Grid container alignItems="center">
          <Grid item xs={7} style={{
                paddingLeft: "20px"
              }}>
            <MDBox
              component="img"
              id={0}
              src={image5}
              alt="small image 1"
              borderRadius="lg"
              shadow="md"
              width="100%"
            />
          </Grid>
          <Grid item xs={5}>
            <MDBox pr={1}>
              <MDBox mb={1}>
                <MDBadgeDot color="info" size="sm" badgeContent="Keto Friendly" />
              </MDBox>
              <MDBox mb={1}>
                <MDBadgeDot color="primary" size="sm" badgeContent="250 Cal" />
              </MDBox>
              <MDBox mb={1}>
                <MDBadgeDot color="dark" size="sm" badgeContent="Taste" />
              </MDBox>
              <MDBox mb={1}>
                <MDBadgeDot color="secondary" size="sm" badgeContent="****" />
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox
        
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        mt="auto"
      >
        <MDBox width={{ xs: "100%", sm: "60%" }} lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="light">
            More than <strong>1,200,000</strong> sales are made using referral marketing, and{" "}
            <strong>700,000</strong> are from social media.
            More than <strong>1,200,000</strong> sales are made using referral marketing, and{" "}
            <strong>700,000</strong> are from social media.
            More than <strong>1,200,000</strong> sales are made using referral marketing, and{" "}
            <strong>700,000</strong> are from social media.
            More than <strong>1,200,000</strong> sales are made using referral marketing, and{" "}
            <strong>700,000</strong> are from social media.
            More than <strong>1,200,000</strong> sales are made using referral marketing, and{" "}
            <strong>700,000</strong> are from social media.

          </MDTypography>
        </MDBox>
      </MDBox>
        </Card> */}
    </Modal>
    <MDBox width="100%">
      <DefaultInfoCard
        icon="local_offer"
        title="Rewards"
        description="You can redeem after every 100 points"
        value={previousOrders.length}
      />
    </MDBox>
    <br />
    <TimelineList title="Previous Orders" onClick={() => {
          handleOpen()
        }}>{renderTimelineItems}</TimelineList>
    
  
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={() => {
        navigate(-1);
       }}
    >
      <Icon fontSize="large" color="inherit">
        home
      </Icon>
    </MDBox>
    </DashboardLayout>
  );
}

export default Myorders;
