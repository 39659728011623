/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import Firebase from "firebase";

// react-github-btn
import GitHubButton from "react-github-btn";

// @mui material components
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import moment from "moment";

// @mui icons
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useCart } from "react-use-cart";
import OrderSummary from "layouts/ecommerce/orders/order-details/components/OrderSummary";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";

// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";


import Header from "layouts/ecommerce/orders/order-details/components/Header";
import OrderInfo from "layouts/ecommerce/orders/order-details/components/OrderInfo";
import TrackOrder from "layouts/ecommerce/orders/order-details/components/TrackOrder";
import PaymentDetails from "layouts/ecommerce/orders/order-details/components/PaymentDetails";
import BillingInformation from "layouts/ecommerce/orders/order-details/components/BillingInformation";
var axios = require('axios');


import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import orderImage from "assets/images/product-12.jpg";
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

// const stripe = require('stripe')('sk_test_51K9af5BfGfOYVrvwXzS2eqyCAv3BatJaf51woRTwmrRlyjA183T0c1agctwFWQTVbbYVmpDB5TvbeFl6yb029dfY00AdQFS0FH');

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setOpenConfigurator,
  setTransparentSidenav,
  setWhiteSidenav,
  setMiniSidenav,
  setFixedNavbar,
  setSidenavColor,
  setDarkMode,
  setOrderId
} from "context";
import CircularProgress from '@mui/material/CircularProgress';

function Configurator() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    openConfigurator,
    miniSidenav,
    fixedNavbar,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    customConfig,
    orderId
  } = controller;
  const [disabled, setDisabled] = useState(false);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [clientSecret, setClientSecret] = useState();
  const [paymentError, setPaymentError] = useState();
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [nameForTheOrder, setNameForTheOrder] = useState();
  const inputStyle = {
    iconColor: '#c4f0ff',
    color: '#ff0',
    fontWeight: '500',
    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    ':-webkit-autofill': {
      color: '#fce883',
    },
    '::placeholder': {
      color: '#87BBFD',
    },
}
  const sidenavColors = ["primary", "dark", "info", "success", "warning", "error"];
  const {
    isEmpty,
    cartTotal,
    totalUniqueItems,
    items,
    updateItemQuantity,
    totalItems,
    removeItem,
    emptyCart,
    addItem,
    metadata,
    getItem
  } = useCart();

  const stripe = useStripe();
  const elements = useElements();


  // Use the useEffect hook to change the button state for the sidenav type based on window size.
  useEffect(() => {
     

    // A function that sets the disabled state of the buttons for the sidenav type.
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    // The event listener that's calling the handleDisabled function when resizing the window.
    window.addEventListener("resize", handleDisabled);

    // Call the handleDisabled function to set the state with the initial value.
    handleDisabled();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleDisabled);
  }, []);
  const navigate = useNavigate();

  const handleCloseConfigurator = () => {
    setPaymentError(null);
    setPaymentProcessing(true)
    setOpenConfigurator(dispatch, false);
  }
 

  const orderInfo = () => {
    return (
      <Grid container spacing={3} alignItems="center">
        
        {items && items.map((product, key) => {
              return (
                <>
                <Grid item xs={9}>

                  <MDBox display="flex" alignItems="center">
                  <MDBox mr={2}>
                    <MDAvatar size='xl' variant="rounded" src={product.image} alt="Gold Glasses" />
                  </MDBox>
                  <MDBox lineHeight={1}>
                    <MDTypography variant="h6" fontWeight="medium">
                     {product.name}
                    </MDTypography>
                    {/* {product.description && (<MDBox mb={2}>
                      <MDTypography variant="button" color="text">
                        {product.description}
                      </MDTypography>
                    </MDBox>)} */}
                    <MDBox mb={2}>
                      <MDTypography variant="button" color="text">
                        Qty: {product.quantity}
                        <br />
                        Price: {product.price}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
                </Grid>
                
                <Grid item xs={3}>
                  <MDBox textAlign="center" >
                      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize" style={{padding: 0}}>
                        ${product.itemTotal}
                      </MDTypography>
                  </MDBox>
                </Grid>
               
                </>
              )
            })
            }        
        {/* <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
          <MDButton variant="gradient" color="dark" size="small">
            contact us
          </MDButton>
          <MDBox mt={0.5}>
            <MDTypography variant="button" color="text">
              Do you like the product? Leave us a review{" "}
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="regular">
                here
              </MDTypography>
              .
            </MDTypography>
          </MDBox>
        </Grid> */}
      </Grid>
    );
  }
  const orderSummary = () => {
    return (
      <>
        <MDBox mb={2}>
          <MDTypography variant="h6" fontWeight="medium">
            Order Summary
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" mb={0.5}>
          <MDTypography variant="button" fontWeight="regular" color="text">
            Product Price:
          </MDTypography>
          <MDBox ml={1}>
            <MDTypography variant="body2" fontWeight="medium">
              ${cartTotal}
            </MDTypography>
          </MDBox>
        </MDBox>
     
        <MDBox display="flex" justifyContent="space-between" mb={0.5}>
          <MDTypography variant="button" fontWeight="regular" color="text">
            Taxes:
          </MDTypography>
          <MDBox ml={1}>
            <MDTypography variant="body2" fontWeight="medium">
              ${Math.ceil(0.1025 * cartTotal)}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" mt={3}>
          <MDTypography variant="body1" fontWeight="light" color="text">
            Total:
          </MDTypography>
          <MDBox ml={1}>
            <MDTypography variant="body1" fontWeight="medium">
              ${Math.ceil(cartTotal + (0.1025 * cartTotal))}
            </MDTypography>
          </MDBox>
        </MDBox>
      </>
    );
  }
  const placeTheOrder = async () => {
    if(customConfig.tableNumber == 'generic' && !nameForTheOrder){
      setPaymentError("Please Enter Name For The Order")
      return ;
    }
    setPaymentError(null);
    setPaymentProcessing(true)

    const amount = Math.ceil(cartTotal + (0.1025 *cartTotal));

    // var data = JSON.stringify({"amount":90000});

    var config = {
      method: 'post',
      url: 'https://us-central1-onroad3-e593b.cloudfunctions.net/stripeCreatePaymentIntent',
      headers: { 
        'Content-Type': 'application/json',
        'id': amount * 100
      }
    };

    const resp = await axios(config)
    if(!resp || (resp && !resp.data)){
      setPaymentError("Something Went Wrong, Please try again. You are not charged")
      setPaymentProcessing(false)
      return;
    }
    // if(!amount){
    //   setPaymentError("Something Went Wrong");
    // }
    // var requestOptions = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json', 'id': amount},
    //   body: JSON.stringify({ "amount": amount})
    // };
   
    // const {client_secret} = await fetch("https://us-central1-onroad3-e593b.cloudfunctions.net/stripeCreatePaymentIntent", requestOptions)
    //   .then(response => response.json())

    // const {client_secret} = await fetch('https://us-central1-onroad3-e593b.cloudfunctions.net/createPaymentIntent', {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({
    //     "amount": 39000,
    //   }),
    // })
    // .then((response) => response.json())
    // .catch((error) => {
    //   console.error("=====error===", error);
    // });

    const {paymentIntent, error} = await stripe
    .confirmCardPayment(resp.data.client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: 'Onroad',
        },
      },
    })

    if(error){
      setPaymentProcessing(false);
      setPaymentError(error.message)
    }
    if(paymentIntent && paymentIntent.status === "succeeded"){
      setPaymentProcessing(false)
      let cart = {}
      for (let index = 0; index < items.length; index++) {
        const product = items[index];
        cart[product.id] = `name:${product.name}||id:${product.id}||price:${product.price}||quantity:${product.quantity}||totalCost:${product.itemTotal}||productimage:${product.image}`
      }

      const currentTime = moment().format("YYYY-MM-DD HH:mm:ss")
      const orderRef = await Firebase.database()
        .ref(`/tableorders/${customConfig.storeUiniqId}/`).push({
          cart: cart,
          table: customConfig.tableNumber,
          time: currentTime,
          name: nameForTheOrder || ""
        })
      const orderFBID = await orderRef.key;
      if(orderId){
        setOrderId(dispatch, `${orderId},${orderFBID}`);
      }else{
        setOrderId(dispatch, orderFBID)
      }
      let localCart = {}
      localCart[currentTime] = {}
      localCart[currentTime][orderFBID]= cart
      const t = await window.localStorage.getItem("onroadcart")
      if(t){
        let a = JSON.parse(t)
        if(a && a.length > 0){
          window.localStorage.setItem('onroadcart', JSON.stringify([localCart, ...a]))
        }else{
          window.localStorage.setItem('onroadcart', JSON.stringify([localCart]))
        }
      }else{
        window.localStorage.setItem('onroadcart', JSON.stringify([localCart]))
      }
      emptyCart();
      setOrderPlaced(true);
    }
  }
    
  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      {orderPlaced ? (
        <>
           <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pt={4}
            pb={0.5}
            px={35}
          >
           

          {!orderPlaced && ( <Icon
              sx={({ typography: { size }, palette: { dark, white } }) => ({
                fontSize: `${size.lg} !important`,
                color: darkMode ? white.main : dark.main,
                stroke: "currentColor",
                strokeWidth: "2px",
                cursor: "pointer",
                transform: "translateY(5px)",
              })}
              onClick={handleCloseConfigurator}
            >
              close
            </Icon>)}
           
          </MDBox>
          
            <MDBox
             justifyContent="center" 
             alignItems="center"
             style={{position: "fixed", transform: "translate(50%, 100%)" }}
            >
              <MDTypography variant="h5">Order is placed</MDTypography>
              <br />
              <MDTypography variant="body2" color="text">
                Food is getting Ready
              </MDTypography>
              <br />
              <MDBox display="flex" alignItems="center" justifyContent="center">
                <MDButton variant="gradient" color="dark" size="large" onClick={() => {
                  handleCloseConfigurator();
                  setOrderPlaced(false);
                  setPaymentError(false)
                  setPaymentProcessing(false)
                  return navigate(-1);
                  }}>
                  Go Home
                </MDButton>
              </MDBox>
              
            </MDBox>
            
        </>
      ) :
      (
        <>
           <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
            pt={4}
            pb={0.5}
            px={3}
          >
            <MDBox>
              <MDTypography variant="h5">Good Choice</MDTypography>
              <MDTypography variant="body2" color="text">
                If you want to add more close this
              </MDTypography>
            </MDBox>

            <Icon
              sx={({ typography: { size }, palette: { dark, white } }) => ({
                fontSize: `${size.lg} !important`,
                color: darkMode ? white.main : dark.main,
                stroke: "currentColor",
                strokeWidth: "2px",
                cursor: "pointer",
                transform: "translateY(5px)",
              })}
              onClick={handleCloseConfigurator}
            >
              close
            </Icon>
          </MDBox>

          <Divider />

          <MDBox pt={0.5} pb={3} >
            
              <MDBox mb={3}>
                {orderInfo()}
              </MDBox>
              <Divider />
              <MDBox mt={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={3} sx={{ ml: "auto" }}>
                    {orderSummary()}
                  </Grid>
                </Grid>
              </MDBox>
              <br />
              {customConfig.tableNumber == 'generic' && (
                <MDBox mb={3}>
                  <MDInput
                    size="small"
                    placeholder="Name for the order..."
                    value={nameForTheOrder}
                    fullWidth
                    onChange={({ currentTarget }) => {
                      setPaymentError(null)
                      setNameForTheOrder(currentTarget.value);
                    }}
                />
                </MDBox>)
              }
              <br />
              <CardElement  options={{
                  // style: {
                  //   base: inputStyle,
                  // },
                }}
                />
              <br />
              {(<></>)}
              
              {paymentError &&
                (<>
                   <MDBox mt={3}  alignItems="center" justifyContent="center">
                    <MDTypography variant="danger"  color="error" fontWeight="medium">
                     {paymentError}
                    </MDTypography>
                  </MDBox>
                    
                </>)
              }
              <br />
              {paymentProcessing && (
                <MDBox mt={3} style={{textAlign: "center"}}>
                  <CircularProgress />
                </MDBox>
              )}
              <MDBox mt={3} style={{textAlign: "center"}}>
                <MDButton variant="gradient" color="dark" size="large" onClick={() => {return placeTheOrder()}}>
                  Place The Order
                </MDButton>
              </MDBox>
              <br />
              <br />
              <br />
              <br />
          </MDBox>
        </>
      )}
     

    </ConfiguratorRoot>
  );
}

export default Configurator;
