
import { GiphyFetch } from '@giphy/js-fetch-api';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import image1 from "assets/images/products/product-details-1.jpg";
import MDAlert from "components/MDAlert";
import MDAvatar from "components/MDAvatar";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { setOpenConfigurator, useMaterialUIController, setCustomConfig } from "context";
import Configurator from "examples/Configurator";
import Footer from "examples/Footer";
// Material Dashboard 2 PRO React examples
import DefaultItem from "examples/Items/DefaultItem";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Firebase from "firebase";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";
import * as React from 'react';
import ImgsViewer from "react-images-viewer";
import { Link } from "react-router-dom";
import { useCart } from "react-use-cart";
import "core-js/actual/array/group-by";

const firebaseConfig = {
  apiKey: "AIzaSyDSaYuHlpFznfk_nhTcitcoGG-8QUGK1ec",
  authDomain: "onroad3-e593b.firebaseapp.com",
  databaseURL: "https://onroad3-e593b-default-rtdb.firebaseio.com",
  projectId: "onroad3-e593b",
  storageBucket: "onroad3-e593b.appspot.com",
  messagingSenderId: "587064425563",
  appId: "1:587064425563:web:99aac8f9a9a8b7cae9137e",
  measurementId: "G-5VFLZ6SK9T"
}
Firebase.initializeApp(firebaseConfig);
const gf = new GiphyFetch('d6r2Pnp7hZLTR6DpOxLDr4fS1H15BBgC')
function Home() {


  let pathname;

 let storeName = "";
 let tableNumber = "";


pathname = window.location.pathname

if(pathname == '/' || !pathname){
window?.location?.replace('https://onroad.app');
}else{
  let params = (new URL(document.location)).searchParams;
  tableNumber = params.get("table");
  storeName = pathname.substring(1).split("-").join(" ").toUpperCase();
  if(!tableNumber || !storeName) {
    window?.location?.replace('https://onroad.app');
  }
}


  const { sales, tasks } = reportsLineChartData;
  const [giphyEmbedURL, setGiphyEmbedURL] = React.useState();  
  const [controller, dispatch] = useMaterialUIController();

  const [newCardForm, setNewCardForm] = React.useState(false);
  const [formValue, setFormValue] = React.useState("");
  const [storeUiniqId, setStoreUiniqId] = React.useState();
  const [list, setList] = React.useState();
  const [groupByList, setGroupByList] = React.useState();

  const [people, setPeople] = React.useState();
  const [currentOffer, setCurrentOffer] = React.useState();
  const [storeLocation, setStoreLocation] = React.useState();
  const [name, setName] = React.useState();
  const [previousOrders, setPreviousOrders] = React.useState();


  const openNewCardForm = (event, id) => setNewCardForm(id);
  const closeNewCardForm = () => setNewCardForm(false);
  const handeSetFormValue = ({ currentTarget }) => setFormValue(currentTarget.value);


  const {
    isEmpty,
    cartTotal,
    totalUniqueItems,
    items,
    updateItemQuantity,
    totalItems,
    removeItem,
    emptyCart,
    addItem,
    metadata,
    getItem
  } = useCart();

  
  const [currentImage, setCurrentImage] = React.useState(image1);
  const [todaySpecialAddCart, setTodaySpecialAddCart] = React.useState(false);
  
  const [currentOrders, setCurrentOrders] = React.useState();

  const [imgsViewer, setImgsViewer] = React.useState(false);
  const [imgsViewerCurrent, setImgsViewerCurrent] = React.useState(0);
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    orderId
  } = controller;

  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);
  const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
  const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const [storeConfig, setStoreConfig] = React.useState();
  const [menuPreviewImages, setMenuPreviewImages] = React.useState();

  React.useEffect(async () => {
    const { data } = await gf.random({ tag: 'cooking' })
    setGiphyEmbedURL(data.embed_url)
  }, []);

  React.useEffect(() => {

    if(!pathname){
      return
    }
    const onChildAdd = Firebase.database()
      .ref(`/pathtoid/${pathname.substring(1)}/`)
      .once('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          setStoreUiniqId(data)
          setCustomConfig(dispatch, {tableNumber: tableNumber, storeUiniqId: data} )
        }
      });
    
    return () => Firebase.database().ref(`/pathtoid/${pathname.substring(1)}/`).off('value', onChildAdd);
  }, [pathname])

  React.useEffect(() => {
    if(!storeUiniqId){
      return
    }
    const onChildAdd = Firebase.database()
      .ref(`/ecommerce/${storeUiniqId}/`)
      .on('value', snapshot => {
        const data = snapshot.val()


        if (data && data.products) {
          let result = []
          const keys = Object.keys(data.products);
          for (let index = keys.length - 1; index > -1; index--) {
            if(data.products[keys[index]] && data.products[keys[index]].name && data.products[keys[index]].price && data.products[keys[index]].stock == 'yes'){
              const element = Object.assign(data.products[keys[index]], {id: keys[index]})
              result.push(element)
            }
          }
          setList(result)
        }
        if (data && data.people) {
          setPeople(data.people[Object.keys(data.people)[0]])
        }
        if (data && data.offers) {
          setCurrentOffer(data.offers[Object.keys(data.offers)[0]])
        }
        if (data && data.storelocation) {
          setStoreLocation(data.storelocation)
        }
        if ( data && data.branches) {
          let result = []
          const keys = Object.keys(data.branches);
          for (let index = keys.length - 1; index > -1; index--) {
            if(data.branches[keys[index]] && data.branches[keys[index]].name && data.branches[keys[index]].address){
              const element = Object.assign(data.branches[keys[index]], {id: keys[index]})
              result.push(element)
            }
          }
          if(result && result.length > 0 && !selectedBranch && !urlOrderID){
            setBranchSelectionModal(true)
          }
          setStoreBranches(result)
        }


        if (data && data.storeconfig) {
          setStoreConfig(data.storeconfig)
          let a = []
          const images = Object.values(data.storeconfig.menuimages)
          if(data.storeconfig && data.storeconfig.menuimages){
            for (let index = 0; index < images.length; index++) {
              a.push({src: images[index]})
            }
          }
          setMenuPreviewImages(a)
        }
      });
    return () => Firebase.database().ref(`/ecommerce/${storeUiniqId}/`).off('value', onChildAdd);
  }, [storeUiniqId])

  React.useEffect(() => {
    const a = window.localStorage.getItem("onroadcart")
    if(a){
      const t = JSON.parse(a)
      if(t.length > 0){
        setPreviousOrders(t)
      }
    }
  }, [])

  React.useEffect(() => {
    if(!orderId){
      return
    }
    
    const listOfOrders = orderId.split(",")
    let currentOrderCart = {}
    if(previousOrders && previousOrders.length > 0){
      for (let index = 0; index < listOfOrders.length; index++) {
        const orderIdtemp = listOfOrders[index];

        for (let index = 0; index < previousOrders.length; index++) {
          const a = previousOrders[index];
          const b = Object.values(a)[0]
          if(Object.keys(b)[0] == orderIdtemp){
            currentOrderCart[orderIdtemp] = Object.values(b)[0]
          }
        }
       
      }
      setCurrentOrders(currentOrderCart)
    }
  }, [previousOrders])
 
  const tipOfTheDay = () => {
    return (
      <>
        <MDAlert color="success" dismissible>
        <MDTypography variant="body2" color="white">
          Tip Of The Day{" "}
          <MDTypography  fontWeight="medium" color="white">
            an example link Give it a click if you like.
          </MDTypography>
        </MDTypography>
        </MDAlert>
        <br />
      </>

    )
  }
  const foodIsGettingReady = () => {
    if(!currentOrders){
      return ;
    }
    const result = []
    const b = Object.values(currentOrders)

    if(!b || b.length == 0){
      return ;
    }
    let d = []
    for (let index = 0; index < b.length; index++) {
      const element = b[index];
      d = d.concat(Object.values(element))
    }
    for (let index = 0; index < d.length; index++) {
      const t = d[index];
     
      result.push({name: t.split("name:")[1].split("||id:")[0], quantity: t.split("||quantity:")[1].split("||totalCost")[0]})
    }

    return (
      <>
      <Card  style={{   
        top: '20%',
        padding: "15px"
      }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDTypography variant="h6">Food is getting ready </MDTypography>
        </MDBox>
        <MDBox mt={3}>
          <Grid container alignItems="center">
            <Grid item>
              <MDBox
              
              >
                <iframe src={giphyEmbedURL} frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
              </MDBox>
            </Grid>
          
          </Grid>
        </MDBox>
        <MDBox
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          mt="auto"
        >
        <MDBox width={{ xs: "100%", sm: "60%" }} lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="light">
            {result.map((i) => {
              return (
                <>
                {i.name } &nbsp; &nbsp;<strong>{i.quantity}</strong> &nbsp;&nbsp;
                </>
              )
            })}
          </MDTypography>
        </MDBox>
        {/* <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <MDTypography variant="h6">Cooking Instructions</MDTypography>
          <MDButton size="small" iconOnly onClick={(event) => openNewCardForm(event, "id")}>
            <Icon
              sx={{
                fontWeight: "bold"
              }}
            >
              add
            </Icon>
          </MDButton>
        </MDBox>
        {newCardForm === "id" ? (
          <MDBox my={2.5}>
            <MDInput
              value={formValue}
              rows="4"
              onChange={handeSetFormValue}
              multiline
              fullWidth
            />
            <MDBox display="flex" mt={2}>
              <MDButton
                variant="gradient"
                color="success"
                size="small"
                onClick={() => {
                  setFormValue("");
                }}
              >
                add
              </MDButton>
              <MDBox ml={1}>
                <MDButton
                  variant="gradient"
                  color="light"
                  size="small"
                  onClick={closeNewCardForm}
                >
                  cancel
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        ) : null} */}
              
        <MDBox width={{ xs: "100%", sm: "40%" }} textAlign="right" mt={{ xs: 2, sm: "auto" }} style={{display: "inherit", textAlign: "right", justifyContent: "right", alignItems: "end" }}>
          {/* <MDBox textAlign="right">
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize" style={{height: "15px", padding: 0}}>
              <MDButton variant="text" color="info" iconOnly size="small" style={{fontSize: "1em!important", fontWeight: "20px"}}>
                <Icon>remove</Icon>
              </MDButton> 
            </MDTypography>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize" style={{height: "15px", padding: 0}}>
              15
            </MDTypography>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize" style={{height: "15px", padding: 0}}>
              <MDButton variant="text" color="info" iconOnly size="small">
                <div style={{fontSize: "1em!important"}}>
                  <Icon>add</Icon>import { Chefnotes } from 'layouts/dashboards/chefnotes';
import tab from '../../../assets/theme/components/tabs/tab';

                </div>
              </MDButton> 
            </MDTypography>
          </MDBox> */}
          {/* <MDBox textAlign="right">
        
          </MDBox> */}

          <MDBox textAlign="right" style={{paddingLeft: "15px"}}>
            {/* <MDButton color={"light"}>
              Order More
            </MDButton> */}
            {/* <MDPagination variant="contained">
              <MDPagination item>
                <Icon>remove</Icon>
              </MDPagination>
              <MDPagination item active>1</MDPagination>
              <MDPagination item>
                <Icon>add</Icon>
              </MDPagination>
            </MDPagination> */}
          </MDBox>
        </MDBox>
        </MDBox>
      </Card>
      <br />
      </>

    )
  }
  const currentCart = () => {
    return (
      <>
         <Card sx={{ height: "100%" }}>            
        <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
          <MDTypography variant="h6" fontWeight="medium">
            Cart
          </MDTypography>
          <MDTypography variant="h5" fontWeight="medium" alignItems="end">
            25
          </MDTypography>
          <MDButton variant="outlined" color="info" size="small" color="error"  onClick={() => handleConfiguratorOpen()} > 
            Checkout
          </MDButton>
        </MDBox>
        <MDBox p={2}>
          <DefaultItem
            color="dark"
            icon="savings"
            title="Cyber Week asdf asdf"
            description="27 March 2020, at 12:30 PM"
          />
          <MDBox mt={2.5}>
            <DefaultItem
              color="dark"
              icon="notifications_active"
              title="Meeting with Marry"
              description="24 March 2020, at 10:00 PM"
            />
          </MDBox>
          <MDBox mt={2.5}>
            <DefaultItem
              color="dark"
              icon="task"
              title="Tasks planification"
              description="24 March 2020, at 12:30 AM"
            />
          </MDBox>
          <MDBox mt={2.5}>
            <DefaultItem
              color="dark"
              icon="notifications_active"
              title="Meeting with Marry"
              description="24 March 2020, at 10:00 PM"
            />
          </MDBox>
        </MDBox>
      </Card>
      <br />
      </>
     
    )
  }

  const mediaPlayerButtonStyles = ({ functions: { pxToRem } }) => ({
    width: pxToRem(46),
    height: pxToRem(46),
    minWidth: pxToRem(46),
    minHeight: pxToRem(46),
    mr: 1,
  });

  const addToCart = () => {
    const item = getItem(3);
    updateItemQuantity(item.id, item.quantity + 1)
  }
  const removeFromCart = () => {
    const item = getItem(3);
    if(item.quantity == 1 ){
      setTodaySpecialAddCart(false)
      removeItem(item.id)
    }else{
      updateItemQuantity(item.id, item.quantity - 1)
    }
  }
  const addToCartAndChangeButtons = () => {
    setTodaySpecialAddCart(true)
    // addToCart
    const product = {
      id: 3,
      name: "Kullen",
      price: 4500
    };
    addItem(product, 1);
  }
  const todaySpecial = () => {
    return (
      <>
      <Card
        sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
          backgroundImage: `${linearGradient(
            rgba(gradients.dark.main, 0.85),
            rgba(gradients.dark.state, 0.85)
          )}, url(${"https://resize.indiatvnews.com/en/resize/oldbucket/715_-/lifestylelifestyle/tempting-jalebi.jpg"})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        })}
      >
        <MDBox p={3} position="relative" lineHeight={0}>
          <MDTypography variant="h5" color="white" fontWeight="medium">
            Today's Special
          </MDTypography>
          <MDTypography variant="button" color="white">
            Deftones
          </MDTypography>
          {!todaySpecialAddCart ? (
            <>
               <MDBox display="flex" mt={3} pt={1} justifyContent="end">
              <MDBox display="flex" alignItems="right" justifyContent="end">
                <MDButton
                  variant="outlined"
                  size="small"
                  onClick={() => {return addToCartAndChangeButtons()}}
                >
                  Add To Cart
                </MDButton>
              </MDBox>
              </MDBox>
            </>
           ) : 
            (
            <MDBox display="flex" alignItems="right" justifyContent="end">
              <MDButton
                variant="outlined"
                size="large"
                circular
                iconOnly
                sx={mediaPlayerButtonStyles}
                onClick={() => {return removeFromCart()}}
              >
                <Icon>remove</Icon>
              </MDButton>
              <MDButton
                variant="outlined"
                size="large"
                circular
                iconOnly
                sx={mediaPlayerButtonStyles}
              >
                {getItem(3).quantity}
              </MDButton>
              <MDButton
                variant="outlined"
                size="large"
                circular
                iconOnly
                sx={mediaPlayerButtonStyles}
                onClick={() => {return addToCart()}}
              >
                <Icon>add</Icon>
              </MDButton>
            </MDBox>)}
        </MDBox>
      </Card>
      <br />
      </>
    )
  }

  const uiItem = (product) => {
    return (
      <Grid container>
          <Grid item xs={11}>
          <MDBox  display="flex" alignItems="center">
            <MDAvatar alt="Avatar" size='xl' variant="rounded" src={product.image}>
            </MDAvatar>
            
              <MDBox ml={2} mt={0.5} lineHeight={1.4} >
                <MDTypography display="block" variant="button" fontWeight="medium"
                style={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical"
                  }}>
                  {product?.name?.replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() })}
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text"
                style={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical"
                  }}>
                  {product.description && product.description}
                </MDTypography>
          
                <MDTypography variant="caption" fontWeight="regular" color="text">
                  {/* <Icon>star</Icon><Icon>star</Icon><Icon>star</Icon><Icon>star</Icon> */}
                  <b>{product?.price}</b>
                </MDTypography>
              </MDBox>
            </MDBox>
            {product?.video && (<MDTypography variant="body2" fontWeight="regular" color="text"  style={{paddingLeft: "20px"}}>
              <MDButton variant="text" color="info" iconOnly size="small" onClick={() => {
                // console.log("=======player===", player.current)
                // if (screenfull.isEnabled) {
                //   screenfull.request(player.current.wrapper);
                // }
                // player.current.seekTo(2);
                window.open("https://www.youtube.com/watch?v=wcJfWoLwe6g", "_blank") 
              }}>
                <Icon style={{fontSize: "30px"}}>play_arrow</Icon> Video
              </MDButton>
            </MDTypography>)}
            </Grid>

          <Grid item xs={1}>
            <MDButton variant="outlined" color="info" iconOnly size="small">
              <Icon>add</Icon>
            </MDButton>
          </Grid>
          </Grid>
    )
  }

  const menuPreview = () => {
    return (
      <>
      <Link to='/menu' state={{ list: list}}>
        <Card sx={{ height: "100%" }}>
          <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
            <MDTypography variant="h6" fontWeight="medium">
              Menu
            </MDTypography>  
            <MDButton variant="outlined" color="info" size="small">
              View Fullmenu
            </MDButton>
          </MDBox>
          <MDBox p={2}>
            {list && list.slice(0, 4).map((product, key) => {
              return (
                <MDBox mt={2.5}>
                  {uiItem(product)}
                </MDBox>
              )
            })
            }
          </MDBox>
          
        </Card>
      </Link>
      <br />
      </> 
    )
  }
  // [{ src: image1 }, { src: image2 }, { src: image3 }, { src: image4 }, { src: image5 }]
  const storeMenuImages = () => {
    return (
      <>
      <Card>
        {menuPreviewImages &&
          (
            <>
            <ImgsViewer
              imgs={menuPreviewImages}
              isOpen={imgsViewer}
              onClose={closeImgsViewer}
              currImg={imgsViewerCurrent}
              onClickPrev={imgsViewerPrev}
              onClickNext={imgsViewerNext}
              zoomable={true}
              drag={true}
              scalable={true}
            />
            <MDBox
              component="img"
              src={menuPreviewImages[0].src}
              alt="Product Image"
              shadow="lg"
              borderRadius="lg"
              width="100%"
              onClick={openImgsViewer}
            />
           
            </>
          )
        }
        
        
      </Card>
      <br />
      </>
   
    )
  }
  const chefNotes = () => {
    return (
      <>
        <Card>   
      <MDBox p={2}>
        <Grid container>

          <Grid item xs={10}>
          <MDBox px={1} display="flex" justifyContent="space-between" alignItems="center">
            <MDTypography variant="h6" fontWeight="medium">
              Chef Notes
            </MDTypography>
            {/* <MDButton variant="text" color="info" size="large" style={{paddingTop: "10px"}}>
              <Icon size="xlarge">arrow_forward</Icon>
              
            </MDButton> */}
          </MDBox>
            <MDBox lineHeight={0.5}  px={1}>
             
              <MDTypography variant="button" fontWeight="bold" color={"success"}>
                Health tips & more ... &nbsp;
                
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={2}>
          <MDBox px={1} display="flex" justifyContent="space-between" alignItems="center">
          <Link to="/chefnotes"  state={{ storeUiniqId: storeUiniqId}}>
            <MDButton variant="text" color="info" size="large" iconOnly >
              <Icon>arrow_forward</Icon>              
            </MDButton>
          </Link>
            
          </MDBox>
           
          </Grid>
          
        </Grid>
      </MDBox>
      </Card>
       <br />
      </>
    
    )
  }

  const profile = () => {
    return (
      <>
      <Card>   
        <MDBox p={2}>
          <Grid container>

            <Grid item xs={10}>
            <MDBox px={1} display="flex" justifyContent="space-between" alignItems="center">
              <MDTypography variant="h6" fontWeight="medium">
                Profile
              </MDTypography>
              {/* <MDButton variant="text" color="info" size="large" style={{paddingTop: "10px"}}>
                <Icon size="xlarge">arrow_forward</Icon>
                
              </MDButton> */}
            </MDBox>
              <MDBox lineHeight={0.5}  px={1}>
    
                <MDTypography variant="button" fontWeight="bold" color={"warning"}>
                  Payment Orders Rewards... &nbsp;
                  
                </MDTypography>
                
              </MDBox>
            </Grid>
            <Grid item xs={2}>
              
            <MDBox px={1} display="flex" justifyContent="space-between" alignItems="center">
            <Link to="/myorders" state={{ previousOrders: previousOrders}}>
              <MDButton variant="text" color="info" size="large" iconOnly >
                <Icon>arrow_forward</Icon>              
              </MDButton>
            </Link>
              
            </MDBox>
            
            </Grid>
            
          </Grid>
        </MDBox>
      </Card>
      <br />
      </>
    )
  }
  return (
    <DashboardLayout>
       {false && tipOfTheDay()}
       {currentOrders && foodIsGettingReady()}
       {false && currentCart()}
       {false && todaySpecial()}
       {menuPreview()}
       {storeMenuImages()}
       {chefNotes()}
       {profile()}
      <Footer />
    </DashboardLayout>
  );
}

export default Home;
