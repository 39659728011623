/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import MediaPlayer from "layouts/pages/widgets/components/MediaPlayer";
import Card from "@mui/material/Card";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import Paper from '@mui/material/Paper';

// Material Dashboard 2 PRO React examples
import DefaultItem from "examples/Items/DefaultItem";
import { useLocation, Link } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "examples/Cards/BookingCard";

// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";
import DataTable from "examples/Tables/DataTable";
import DefaultCell from "./DefaultCell";

function Deals() {
  const { sales, tasks } = reportsLineChartData;
  const dataTableData = {
    columns: [
      {
        Header: "date",
        accessor: "date",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "product",
        accessor: "product",
        Cell: ({ value }) => {
          const [name, data] = value;
  
          return (
            <DefaultCell
              value={typeof value === "string" ? value : name}
              suffix={data.suffix || false}
            />
          );
        },
      },
      { Header: "revenue", accessor: "revenue", Cell: ({ value }) => <DefaultCell value={value} /> },
    ],
  
    rows: [
      {
        date: "1 Nov, 10:20 AM",
        product: "Nike Sport V2",
        revenue: "$140,20",
      },
      {
        date: "1 Nov, 10:53 AM",
        product: "Valvet T-shirt",
        revenue: "$42,00",
      },
      {
        date: "1 Nov, 10:20 AM",
        product: "Nike Sport V2",
        revenue: "$140,20",
      },
      {
        date: "1 Nov, 10:53 AM",
        product: "Valvet T-shirt",
        revenue: "$42,00",
      },
      {
        date: "1 Nov, 10:20 AM",
        product: "Nike Sport V2",
        revenue: "$140,20",
      },
      {
        date: "1 Nov, 10:53 AM",
        product: "Valvet T-shirt",
        revenue: "$42,00",
      },
      {
        date: "1 Nov, 10:20 AM",
        product: "Nike Sport V2",
        revenue: "$140,20",
      },
      {
        date: "1 Nov, 10:53 AM",
        product: "Valvet T-shirt",
        revenue: "$42,00",
      }
    ],
  };
  // Action buttons for the BookingCard
  const actionButtons = (
    <>
      <Tooltip title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Edit" placement="bottom">
        <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );
 
  return (
    <DashboardLayout>
      <MediaPlayer />
    </DashboardLayout>
  );
}

export default Deals;
